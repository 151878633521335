import { Box, Button, Snackbar } from "@mui/material";
import { config, generateTimeStr, generateUUID } from "../Const";
import { useEffect, useState } from "react";
import FacebookGroup from "./FacebookGroup";
import XPost from "./XPost";
import { executeFetch } from "../Util";




export default function Publish({ seoTitle, type }) {

    const [markPublishedSnackbarOpen, setMarkPublishedSnackbarOpen] = useState(false);
    const pageUrl = `${config.APP_URL_HOST}/${type.toLowerCase()}/${seoTitle}`;
    const [facebookGroups, setFacebookGroups] = useState([]);
    const [publishedTokenList, setPublishedTokenList] = useState([]);
    const [xPageUrl, setXPageUrl] = useState('');



    const generatefbUrl = (groupId) => {
        return `${pageUrl}?t=f${groupId}${generateTimeStr()}`;
    }

    const handleCopyClick = (url) => {

        navigator.clipboard.writeText(url);

    }

    const handleFacebookGroupChange = (id, groupId) => {

        const fbUrl = generatefbUrl(groupId);

        const updatedFg = { id: id, groupId: groupId, url: fbUrl };

        setFacebookGroups(
            facebookGroups.map(fg => fg.id === id ? updatedFg : fg)
        );

    }




    const generateFBGroup = () => {

        return { id: generateUUID() };
    }


    const handleAddGroup = () => {

        const fbGroup = generateFBGroup();

        setFacebookGroups([...facebookGroups, fbGroup])


    }

    const isMarkPublishedButtonDisabled = (url) => {


        if (url) {
            const token = url.substring(url.indexOf("?t=") + 3);
            return publishedTokenList.some(obj => obj.token === token);
        } else {
            return false;
        }
    }

    const handleMarkPublishedClick = (url) => {
        const publishToken = url.replace(pageUrl + '?t=', '');

        const formData = new FormData();
        formData.append('type', type);
        formData.append('seoTitle', seoTitle);
        formData.append('token', publishToken);

        const apiUrl = `${config.SERVER_URL}/api/v1/admin/quiz/markPublished`;

        const responseDataHandler = (result) => {
            setMarkPublishedSnackbarOpen(true);
            setPublishedTokenList([...publishedTokenList, { token: publishToken, publishDate: result.publishDate }]);

        }

        executeFetch(apiUrl, "post", null, formData, null, responseDataHandler);
    }

    const handleDeletePublishFBClick = (id) => {

        const fbGroup = facebookGroups.filter(fg => fg.id === id)[0];
        const url = fbGroup.url;

        handleDeletePublishClick(url)

        setFacebookGroups(facebookGroups.filter(fg => fg.id !== id));

    }

    const handleDeleteXClick = (url) => {

        handleDeletePublishClick(url)
        setXPageUrl(`${pageUrl}?t=x${generateTimeStr()}`);



    }

    const handleDeletePublishClick = (url) => {
        const publishToken = url.replace(pageUrl + '?t=', '');
        const isPublished = publishedTokenList.some(pt => pt.token === publishToken);

        // Only if this is a saved PagePublish, send an API call to delete it
        if (isPublished) {

            const formData = new FormData();
            formData.append('token', publishToken);

            const apiUrl = `${config.SERVER_URL}/api/v1/admin/quiz/deletePublished`;

            const responseDataHandler = (result) => {

            }

            executeFetch(apiUrl, "post", null, formData, null, responseDataHandler);
        }
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMarkPublishedSnackbarOpen(false);
    };

    const getPublishDateFromUrl = (url) => {
        let publishToken = url?.replace(pageUrl + '?t=', '');
        let publishDate = publishedTokenList.find(pt => pt.token === publishToken)?.publishDate;
        return publishDate;
    }


    useEffect(() => {

        const apiUrl = `${config.SERVER_URL}/api/v1/admin/quiz/pagePublishList?type=${type}&seoTitle=${seoTitle}`;

        const responseDataHandler = (result) => {

            setPublishedTokenList(result.map(p => ({ token: p.token, publishDate: p.publishDate })));


            let xPagePublish = result.filter(p => p.token.charAt(0) === 'x')[0] || null;

            if (xPagePublish) {

                let token = xPagePublish.token.substring(1);
                setXPageUrl(`${pageUrl}?t=x${token}`);
            } else {
                setXPageUrl(`${pageUrl}?t=x${generateTimeStr()}`);
            }


            setFacebookGroups(
                result
                    .filter(p => p.token.charAt(0) === 'f')
                    .map(p => {

                        const groupId = p.token.substring(1, p.token.length - 8);
                        const timePart = p.token.substring(p.token.length - 8, p.token.length);

                        return {
                            id: p.id,
                            groupId: groupId,
                            url: `${pageUrl}?t=f${groupId}${timePart}`,
                            publishDate: p.publishDate

                        }
                    })
            );


        }

        executeFetch(apiUrl, "get", null, null, null, responseDataHandler)



    }, [type, seoTitle, pageUrl]);


    return (


        <Box>

            <Box sx={{ fontWeight: 700 }}>Quiz URL</Box>


            {seoTitle && <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>

                <Box>{pageUrl}</Box>
                <Button size={'small'} variant="outlined" onClick={() => handleCopyClick(pageUrl)}>Copy</Button>


            </Box>}

            <Box sx={{ fontWeight: 700, mt: '30px' }}>X Post URL</Box>

            <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                <Box sx={{ width: '606px' }}><XPost url={xPageUrl} /></Box>
                <Box sx={{ display: 'flex', gap: '10px', fontSize: '0.9rem', alignItems: 'center' }}>
                    <Button size={"small"} variant="outlined" onClick={() => handleCopyClick(xPageUrl)}>Copy</Button>
                    <Button size={'small'} sx={{ minWidth: '150px' }} disabled={isMarkPublishedButtonDisabled(xPageUrl)} variant="contained" onClick={() => handleMarkPublishedClick(xPageUrl)}>Mark Published</Button>


                    <Box sx={{ width: '200px' }}>

                        {(() => {
                            let publishDate = getPublishDateFromUrl(xPageUrl);
                            let publishDateFormatted = publishDate ? new Date(publishDate).toLocaleString() : '';
                            return publishDateFormatted;
                        })()}
                    </Box>

                    <Button color={'error'} size={'small'} variant="contained" disabled={!isMarkPublishedButtonDisabled(xPageUrl)} onClick={() => handleDeleteXClick(xPageUrl)}>Delete</Button>

                </Box>
            </Box>

            <Box key={'fb-' + seoTitle} sx={{ display: 'flex', mt: '40px', gap: '40px', width: '100%', alignItems: 'center' }}>
                <Box sx={{ fontWeight: 700 }}>Facebook Posts</Box>
                <Button size={'small'} variant="contained" onClick={handleAddGroup}>Add Group</Button>
            </Box>
            <Box>

                {
                    facebookGroups.map(fg => {

                        let fgUrl = fg.url;
                        let publishDate = getPublishDateFromUrl(fgUrl);
                        let publishDateFormatted = new Date(publishDate).toLocaleString();



                        return (

                            <Box sx={{ display: 'flex', gap: '20px', mt: '20px', mb: '20px', width: '800px', alignItems: 'center' }}>
                                <FacebookGroup key={fg.fbUrl} fg={fg} disabled={isMarkPublishedButtonDisabled(fg.url)} onChange={(value) => handleFacebookGroupChange(fg?.id, value)} />

                                <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                    <Button size={'small'} variant="outlined" onClick={() => handleCopyClick(fg.url)}>Copy</Button>
                                    <Button sx={{ minWidth: '150px' }} size={'small'} disabled={isMarkPublishedButtonDisabled(fg.url)} variant="contained" onClick={() => handleMarkPublishedClick(fg.url)}>Mark Published</Button>

                                    <Box sx={{ width: '200px', fontSize: '0.9rem' }}>{publishDate && <Box>{publishDateFormatted}</Box>}</Box>
                                    <Button color={'error'} size={'small'} variant="contained" disabled={!isMarkPublishedButtonDisabled(fg.url)} onClick={() => handleDeletePublishFBClick(fg.id)}>Delete</Button>


                                </Box>

                            </Box>
                        )
                    }
                    )

                }

            </Box>

            <Snackbar
                open={markPublishedSnackbarOpen}
                autoHideDuration={3000}
                message="Page Marked as published"
                onClose={handleSnackbarClose}

            />


        </Box>
    )

}