// UserTable.js
import React, { useState, useEffect, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Checkbox,
    TablePagination,
    TextField,
    Button,
    Snackbar,
    Alert,
} from '@mui/material';
import { debounce } from 'lodash';
import { executeFetchAsync, formatDate } from '../Util';
import EmailImportDialog from './EmailImportDialog';  // Import the dialog component
import { config } from '../Const';

export default function UserTable({ onSelect, userList }) {
    const [filteredUserList, setFilteredUserList] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [emailImportDialog, setEmailImportDialog] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleCheckboxChange = (id) => {
        setSelected((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((item) => item !== id)
                : [...prevSelected, id]
        );
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelected(filteredUserList.map((row) => row.id));
        } else {
            setSelected([]);
        }
    };

    const isSelected = (id) => selected.includes(id);

    const handleRowClick = (id) => {
        onSelect(id);
        setSelectedRow((prevSelectedRow) => (prevSelectedRow === id ? null : id));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        debouncedSearch(event.target.value);
    };

    const debouncedSearch = useMemo(
        () =>
            debounce((query) => {
                const lowerCaseQuery = query.toLowerCase();
                const filtered = userList.filter(
                    (user) =>
                        user.userName.toLowerCase().includes(lowerCaseQuery) ||
                        user.email.toLowerCase().includes(lowerCaseQuery)
                );
                setFilteredUserList(filtered);
                setPage(0);
            }, 300),
        [userList]
    );

    useEffect(() => {
        return () => debouncedSearch.cancel();
    }, [debouncedSearch]);

    useEffect(() => {
        setFilteredUserList(userList);
    }, [userList]);

    const exportEmailsAsCSV = () => {
        const emailList = filteredUserList.map((user) => user.email);
        const csvContent = [
            ['Email'], // Column header
            ...emailList.map((email) => [email]),
        ]
            .map((row) => row.join(','))
            .join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'user_emails.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };


    const handleEmailImport = async (campaignName, campaignDate, emails) => {
        if (!emails) {
            console.error("No emails provided.");
            return;
        }

        try {

            const endPoint = `${config.SERVER_URL}/api/v1/admin/user/email-campaign-bulk-import`;

            const body = JSON.stringify({
                campaignName, campaignDate, emails
            });

            const headers = { 'Content-Type': 'application/json' };

            const result = await executeFetchAsync(endPoint, 'POST', headers, body);

            setFilteredUserList(result);

            setSnackbarMessage('Email campaign imported successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);

        } catch (error) {

            setSnackbarMessage('Error importing email campaign.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };



    return (
        <Box sx={{ height: 800, p: 5, overflowY: 'scroll' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />

                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Button onClick={exportEmailsAsCSV}>Export</Button>
                    <Button onClick={() => setEmailImportDialog(true)}>Import Email Log</Button>
                </Box>
            </Box>

            <TableContainer>
                <Table size="small" aria-label="user table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1565c0', color: 'white' }}>
                            <TableCell sx={{ color: 'white' }}>
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < filteredUserList.length}
                                    checked={selected.length === filteredUserList.length}
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                            <TableCell sx={{ color: 'white' }}>ID</TableCell>
                            <TableCell sx={{ color: 'white' }}>User Name</TableCell>
                            <TableCell sx={{ color: 'white' }}>Email</TableCell>
                            <TableCell sx={{ color: 'white' }}>Status</TableCell>
                            <TableCell sx={{ color: 'white' }}>Last Usage</TableCell>
                            <TableCell sx={{ color: 'white' }}>Last Email</TableCell>
                            <TableCell sx={{ color: 'white' }}># Played</TableCell>
                            <TableCell sx={{ color: 'white' }}>Top Category</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUserList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((user, index) => (
                                <TableRow
                                    key={user.id}
                                    sx={{
                                        backgroundColor: selectedRow === user.id ? 'rgba(25, 118, 210, 0.3)' : index % 2 === 0 ? 'action.hover' : 'inherit',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleRowClick(user.id)}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isSelected(user.id)}
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                handleCheckboxChange(user.id);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>{user.id}</TableCell>
                                    <TableCell>{user.userName}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.accountStatus}</TableCell>
                                    <TableCell>{formatDate(user.lastActiveDate)}</TableCell>
                                    <TableCell>{formatDate(user.lastEmailDate)}</TableCell>
                                    <TableCell>{user.quizzesPlayed}</TableCell>
                                    <TableCell>{user.favoriteCategory}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <TablePagination
                    component="div"
                    count={filteredUserList.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                />
            </Box>

            <EmailImportDialog
                open={emailImportDialog}
                onClose={() => setEmailImportDialog(false)}
                onImport={handleEmailImport}
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}