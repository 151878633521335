import React, { useEffect } from 'react';
import { useState } from 'react';
import { generateImageUrlFromImd } from '../Util.js';
import placeHolderImage from '../icons/placeholder_image.jpg';
import Gallery from './Gallery';
import { Box, IconButton, Paper, Dialog, DialogContent } from '@mui/material';
import ImageSearchTwoToneIcon from '@mui/icons-material/ImageSearchTwoTone';
import ClearIcon from '@mui/icons-material/Clear';
import InputContainer from '../InputContainer.jsx';


function ImageSelector(props) {


    const imd = props.imd;

    const galleryDataUrl = props.galleryDataUrl
    const alt = imd?.alt ?? imd?.description ?? '';


    const lockAspect = props.lockAspect;
    const handleAltChange = props.onAltChange;

    const [galleryOpen, setGalleryOpen] = useState(false);
    const [zoomImagePopupOpen, setZoomImagePopupOpen] = useState(false);

    const [imageUrl, setImageUrl] = useState(null);


    const handleSelect = props.onSelect;


    const closeZoomImagePopup = () => {

        setZoomImagePopupOpen(false);

    }

    const handleImageSelected = (imd) => {

        setGalleryOpen(false);

        handleSelect(imd);
    }


    const zoomImage = () => {
        setZoomImagePopupOpen(true);
    }


    const getZoomImageWidth = (imd) => {

        if (imd == null) return 0;
        let imdWidth = imd.crop ? imd.crop.width : imd.width;
        return Math.min(imdWidth, 700)
    }

    const getZoomImageHeight = (imd) => {
        if (imd == null) return 0;
        let imdHeight = imd.crop ? imd.crop.height : imd.height;
        return Math.min(imdHeight, 700)
    }

    const handleSelectImageClicked = () => {
        setGalleryOpen(!galleryOpen);
    };

    const removeImage = () => {
        handleSelect(null);
    }


    const generateQuizImageUrl = () => {

        let url = null;

        if (imd == null) {
            url = placeHolderImage;
        } else if (imd.crop == null) {
            url = imd.url;
        } else {
            url = imageUrl;
        }

        return url;


    }


    useEffect(() => {
        const fetchImageUrl = async () => {
            const imageBlobUrl = await generateImageUrlFromImd(imd);
            setImageUrl(imageBlobUrl);
        };


        fetchImageUrl();
    }, [imd]);

    return (
        <Paper elevation={5}>

            <Box>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', width: '30%', height: '100%' }}>
                        <img alt={imageUrl} onClick={zoomImage} src={imageUrl ? imageUrl : placeHolderImage} width='100%'></img>


                        <Box sx={{ display: 'flex', position: 'relative', left: '-33px', flexDirection: 'column', justifyContent: 'space-between' }}>

                            {imageUrl && <IconButton onClick={removeImage} size="small" ><ClearIcon sx={{ marginLeft: '3px;', opacity: 0.7, backgroundColor: 'white', borderRadius: 1 }} fontSize="small"></ClearIcon></IconButton>}

                            {!imageUrl && <Box />}

                            <IconButton aria-label="edit" size="small" onClick={handleSelectImageClicked} ><ImageSearchTwoToneIcon sx={{ opacity: 0.7, backgroundColor: 'white', borderRadius: 1 }} fontSize="medium" /></IconButton>

                        </Box>



                    </Box>
                    <Box sx={{ width: '70%' }}>
                        <InputContainer multiline label={'Alt Text'} value={alt} onBlur={handleAltChange}></InputContainer>
                    </Box>

                </Box>


            </Box>




            {
                zoomImagePopupOpen && imd &&
                <Dialog open={true} onClose={closeZoomImagePopup} paperProps={{ width: getZoomImageWidth(imd), height: getZoomImageHeight(imd), padding: 10 }}>
                    <img width={getZoomImageWidth(imd)} alt={imd.description} src={generateQuizImageUrl(imd)}></img>
                </Dialog>
            }

            <Dialog open={galleryOpen} PaperProps={{
                sx: {
                    width: "100%",
                    maxWidth: "1024px!important",
                    height: '100%'

                },
            }} onClose={() => setGalleryOpen(false)} fullWidth>

                <DialogContent sx={{ overflowY: 'hidden', height: '100%' }}>
                    <Gallery lockAspect={lockAspect} onSelect={handleImageSelected} dataUrl={galleryDataUrl}></Gallery>
                </DialogContent>

            </Dialog>
        </Paper >

    )

}

export default ImageSelector;

