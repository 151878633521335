import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,

} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatDate } from '../../Util';

export default function Comms({ user }) {

    const statusArray = ['SEND_EMAIL'];
    const accountLog = user?.accountLog;
    const emailLog = accountLog?.filter(ale => statusArray.indexOf(ale.alm) > -1);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="email-log-content" id="email-log-header">
                <Typography sx={{ mr: 2 }}>Emails ({emailLog?.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>

                    {emailLog?.map((as, index) => (

                        <Grid
                            key={index}
                            container
                            spacing={1}
                            sx={{ fontSize: '0.8em', mt: '10px' }}
                            alignItems="center"
                        >
                            <Grid item xs={4}>
                                {formatDate(as.date)}
                            </Grid>
                            <Grid item xs={8}>
                                {as.message.split("~~~")[0]}
                            </Grid>
                        </Grid>


                    ))}



                </Box>
            </AccordionDetails>

        </Accordion>
    );
}
