import React from 'react';
import { useState } from 'react';
import { Box, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Button, Typography, Paper } from '@mui/material';

function QuizSettings(props) {

    let initSettings = props.settings;


    if (initSettings == null) {
        initSettings = generateDefaultSettings;
    }


    const [settings, setSettings] = useState(initSettings);

    const okClick = () => {
        props.onSave(settings);
    }
    const cancelClick = props.onCancel;

    const showAnswersChange = (e) => {
        const { value } = e.target;
        let settingsClone = JSON.parse(JSON.stringify(settings))
        settingsClone.showAnswers = value;
        setSettings(settingsClone);

    }




    return (
        <Box elevation="3" sx={{ padding: '20px', height: '100%' }}>

            <Typography variant='h4' sx={{ marginBottom: '40px' }}>Quiz Settings</Typography>

            <Paper elevation="5" sx={{ height: '71%', padding: '20px' }}>

                <FormControl>
                    <FormLabel>Show Answers</FormLabel>
                    <RadioGroup row value={settings.showAnswers} defaultValue={1} onChange={showAnswersChange}                    >
                        <FormControlLabel value={0} control={<Radio size="small" />} label="Immediately" />
                        <FormControlLabel value={1} control={<Radio size="small" />} label="At the end" />

                    </RadioGroup>
                </FormControl>


            </Paper>

            <Box sx={{ marginTop: '20px', display: 'flex', gap: '20px', width: '100%', justifyContent: 'right' }}>
                <Button size="small" variant="outlined" onClick={cancelClick}>Cancel</Button>
                <Button size="small" variant="contained" onClick={okClick}>OK</Button>
            </Box>

        </Box>
    )

}

export default QuizSettings;

export const generateDefaultSettings = () => {
    return { 'includesImageIdentification': false, 'includesAudioIdentification': false, 'popular': false };
}

