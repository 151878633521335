import React, { useState } from 'react';
import QuestionEditor from "./QuestionEditor.jsx";
import QuizManagePanel from "./QuizManagePanel.jsx";
import { Box } from '@mui/material';
import { config } from "../Const.js";
import { executeFetch } from '../Util.js';




function QuizAdmin(props) {

    const [questionData, setQuestionData] = useState([]);
    const [previewEditorContent, setPreviewEditorContent] = useState('');

    const handleNewQuiz = () => {

        setQuestionData([]);
        setPreviewEditorContent('');

    }

    const handleLoadQuiz = (questionList, previewEditorContent) => {

        setQuestionData(questionList);
        setPreviewEditorContent(previewEditorContent);

    }

    const handleDeleteQuestion = (q) => {

        let questionDataClone = JSON.parse(JSON.stringify(questionData));
        let index = questionDataClone.findIndex((i) => i.question.templateId === q.question.templateId);

        questionDataClone.splice(index, 1);
        setQuestionData(questionDataClone);
    }

    const handleAddQuestion = (q) => {

        let questionDataClone = JSON.parse(JSON.stringify(questionData));

        questionDataClone.push(q);
        setQuestionData(questionDataClone);
    }


    const handleChangeQuestion = (q) => {

        let questionDataClone = JSON.parse(JSON.stringify(questionData));
        let index = questionDataClone.findIndex((i) => i.question.templateId === q.question.templateId);

        questionDataClone[index] = q;
        setQuestionData(questionDataClone);
    }

    const handleUpDownQuestion = (dir, q) => {

        let questionDataClone = JSON.parse(JSON.stringify(questionData));
        let index = questionDataClone.findIndex((i) => i.question.templateId === q.question.templateId);

        if (index + dir >= 0 && index + dir < questionDataClone.length) {

            questionDataClone[index] = questionDataClone[index + dir];
            questionDataClone[index + dir] = q;
            setQuestionData(questionDataClone);
        }
    }

    const handleCreateNewQuestion = () => {

        let formData = new FormData();
        formData.append('questionParamsJson', '{}');

        const url = config.SERVER_URL + "/api/v1/admin/generateQuestions/GeneralMCQuestion/edit";

        const responseHandler = (newQuestion) => {
            newQuestion.question.templateId = newQuestion.question.templateId.split("~~~")[0] + "~~~" + Math.random();
            let clonedQuestionData = JSON.parse(JSON.stringify(questionData));
            clonedQuestionData.unshift(newQuestion);
            setQuestionData(clonedQuestionData);
        }

        executeFetch(url, "post", null, formData, null, responseHandler);

    }



    return (


        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{
                display: 'flex', maxWidth: '1400px'
            }}>

                <Box sx={{ pl: '20px', pt: '20px', mr: '15px', minWidth: '600px', height: '94vh' }}>
                    <QuizManagePanel questionData={questionData} previewEditorContent={previewEditorContent} onNewQuiz={handleNewQuiz} onLoad={handleLoadQuiz}></QuizManagePanel>
                </Box>

                <Box sx={{ pl: '20px', pt: '20px', mr: '15px', minWidth: '800px', height: '94vh' }}>
                    <QuestionEditor questionData={questionData} onCreateNewQuestion={handleCreateNewQuestion} onAddQuestion={handleAddQuestion} onDeleteQuestion={handleDeleteQuestion} onChangeQuestion={handleChangeQuestion} onUpDownQuestion={handleUpDownQuestion}></QuestionEditor>
                </Box>




            </ Box >

        </Box>


    )

};

export default QuizAdmin;
