import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Accordion, AccordionSummary, AccordionDetails, TextField, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function BasicDetails({ user, onSave }) {
    const [editMode, setEditMode] = useState(false);
    const [email, setEmail] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);

    const handleEditClick = (e) => {
        e.stopPropagation();
        setEditMode(!editMode);
        setIsExpanded(true);
    };

    const handleAccordionChange = (_, expanded) => {
        setIsExpanded(expanded);
    };

    const handleSaveClick = () => {
        setEditMode(false);
        if (onSave) {
            onSave(email);
        }
    };

    useEffect(() => {
        setEmail(user?.email);
    }, [user]);

    return (
        <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="user-details-content" id="user-details-header">
                <Typography sx={{ mr: 2 }}>User Details</Typography>
                <EditIcon onClick={handleEditClick} />
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                ID:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{user?.id || 'N/A'}</Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Name:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{user?.userName || 'N/A'}</Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Email:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                {editMode ? (
                                    <>
                                        <TextField
                                            size="small"
                                            sx={{ width: 250 }}
                                            value={email}
                                            onChange={(e) => setEmail(e.currentTarget.value)}
                                        />

                                    </>
                                ) : (
                                    <Typography>{email || 'N/A'}</Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    {editMode && <Box display="flex" justifyContent="center" sx={{ mt: 3, mr: 3 }}>
                        <Button
                            sx={{ width: '150px' }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleSaveClick}
                        >
                            Save
                        </Button>
                    </Box>}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}
