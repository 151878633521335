import { config } from "./Const.js";
import placeHolderImage from './icons/placeholder_image.jpg';

export const generateImageUrl = async (url, x, y, width, height) => {

    const cropUrl = `${config.SERVER_URL}/api/v1/admin/image/crop/${encodeURIComponent(url)}/${x}/${y}/${width}/${height}`
    const res = await executeFetchAsync(cropUrl, "get");

    const blob = await res.blob();
    const imageUrl = URL.createObjectURL(blob);

    return imageUrl;

}



export const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}



export const generateImageUrlFromImd = async (imd) => {

    if (imd != null) {
        if (imd.crop == null) {
            return imd.url;
        } else {
            return await generateImageUrl(imd.url, imd.crop.x, imd.crop.y, imd.crop.width, imd.crop.height);
        }
    }
}

export const formatDate = (dateString) => {
    if (!dateString) return;
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export const generateImageUrlFromCDN = (imd) => {

    if (imd != null && imd.storedImageFileName != null) {

        return config.CDN_URL + "/" + imd.storedImageFileName;

    } else {
        return placeHolderImage;
    }
}

export const handleResponse = (res, errorHandler) => {
    if (!res.ok) {
        let status = res.status;
        if (status === 401) {

            localStorage.removeItem("token");

            if (errorHandler) {
                errorHandler();
            } else {
                window.location.replace("/");
            }

        }
    } else {

        let contentType = res.headers.get("content-type");

        if (contentType === "application/json") {

            return res.json();
        } else {
            return res.text();
        }

    }
}


export const executeFetchAsync = async (url, method, headers, body, signal) => {
    method = method || "get";
    if (!headers) headers = {};
    let token = localStorage.getItem('token');

    if (token) {
        headers['Authorization'] = 'Bearer ' + token;
    }

    try {
        let result;
        const response = await fetch(url, {
            signal: signal,
            body: body,
            method: method ? method : "GET",
            headers: headers,
        });

        if (!response.ok) {
            // Handle HTTP errors (e.g., 415)
            const errorMessage = `HTTP error ${response.status}: ${response.statusText}`;
            console.error(errorMessage);
            throw new Error(errorMessage);
        }



        let contentType = response.headers.get("content-type");

        if (contentType === "application/json") {

            result = await response.json();
        } else {
            result = await response.text();
        }

        return result;


    } catch (e) {
        if (e.name === 'AbortError') {
            return;
        }

        console.error('Unexpected error:', e);
        throw e;

    }
};




export const executeFetch = (url, method, headers, body, signal, responseDataHandler, errorDataHandler) => {

    if (!headers) headers = {};
    let token = localStorage.getItem('token');

    if (token) {
        headers['Authorization'] = 'Bearer ' + token;
    }

    try {

        fetch(url, {
            signal: signal,
            body: body,
            method: method ? method : "get",
            headers: headers,


        })
            .then((res) => {
                return handleResponse(res, errorDataHandler);

            })
            .then(responseDataHandler);
    } catch (e) {
        if (e.name === 'AbortError') {
            return;
        }
        //console.error('Unexpected error:', e);
    }

};