import { Box } from "@mui/material";
import UserTable from "./UserTable";
import UserPanel from "./UserPanel";
import { useEffect, useState } from "react";
import { executeFetchAsync } from "../Util";
import { config } from "../Const";

export default function UserAdmin() {

    const [selectedUserId, setSelectedUserId] = useState(null);
    const [userList, setUserList] = useState([]);


    useEffect(() => {
        const fetchUserList = async () => {
            const url = `${config.SERVER_URL}/api/v1/admin/user`;
            const userList = await executeFetchAsync(url);

            setUserList(userList);
        };
        fetchUserList();
    }, []);

    const handleChangeUser = (user) => {
        setUserList((prevUserList) =>
            prevUserList.map((existingUser) =>
                existingUser.id === user.id
                    ? { ...existingUser, email: user.email, accountStatus: user.accountStatus }
                    : existingUser
            )
        );
    };

    const handleDeleteUser = (user) => {
        setSelectedUserId(null);
        setUserList((prevUserList) =>
            prevUserList.filter((existingUser) => existingUser.id !== user.id)
        );
    };


    return (

        <Box sx={{ display: 'flex' }}>

            <Box sx={{ flexGrow: 1, maxWidth: `calc(100% - 500px)` }}>
                <UserTable onSelect={setSelectedUserId} userList={userList} />
            </Box>

            <Box sx={{ width: '500px', flexShrink: 0 }}>
                <UserPanel userId={selectedUserId} onChangeUser={handleChangeUser} onDeleteUser={handleDeleteUser} />
            </Box>
        </Box>
    )

}