import React, { useState, useEffect } from 'react';
import { config } from "../Const.js";
import QuestionContainer from './QuestionContainer.jsx';
import { CircularProgress, Box } from '@mui/material';
import { executeFetch } from '../Util.js';
import SearchBox from './SearchBox.jsx';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';


import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';




function QuestionBank(props) {

    const [entityId, setEntityId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [questionData, setQuestionData] = useState([]);


    const handleEntitySelected = (entity) => { setEntityId(entity.id) };
    const notifyAddQuestion = props.onAddQuestion;

    const handleDuplicate = (q) => {

        let clonedQuestionData = JSON.parse(JSON.stringify(questionData));
        let clonedQuestion = JSON.parse(JSON.stringify(q));

        clonedQuestion.question.templateId = q.question.templateId.split("~~~")[0] + "~~~" + Math.random();

        let index = questionData.findIndex((i) => i.question.templateId === q.question.templateId);

        clonedQuestionData.splice(index + 1, 0, clonedQuestion);

        setQuestionData(clonedQuestionData);

    }


    useEffect(() => {

        if (!entityId) return

        setIsLoading(true);

        let url = config.SERVER_URL + "/api/v1/admin/generateQuestions/" + entityId + "/all";

        let responseHandler = (result) => {
            setIsLoading(false);
            setQuestionData(result)
        }

        executeFetch(url, "get", null, null, null, responseHandler);

    }, [entityId]);


    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({

        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(0, 0, 0, .05)'
                : 'rgba(233, 238, 241)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(0),
        },

    }));



    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(0),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));

    const isAudioQuestion = (questionText) => {
        return questionText && questionText.startsWith('^^');
    }




    var qList = questionData.map(function (q) {
        let textMessage = q.question.textMessage;
        if (isAudioQuestion(textMessage)) {
            textMessage = <Box sx={{ display: 'flex' }}><AudiotrackIcon />{textMessage.substring(textMessage.lastIndexOf("^^") + 2)}</Box>;
        }


        return <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="subtitle1" sx={{ alignItems: 'center' }}>{textMessage}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <QuestionContainer key={q.question.templateId} data={q} onAdd={notifyAddQuestion} selectedMode={false} onDuplicate={handleDuplicate} ></QuestionContainer >
            </AccordionDetails>
        </Accordion>



    });
    return (


        <Box sx={{ height: '100%', pl: '10px', pr: '20px', overflowY: 'auto' }}>

            <SearchBox endPoint={'entity'} onEntitySelected={handleEntitySelected} resultMappingFunction={(r) => {
                return { label: r.label, id: r.idMapping.WIKIDATA };
            }} />

            <Box sx={{ mt: '20px' }}>

                {isLoading && <Box sx={{ padding: '10px' }}><CircularProgress /></Box>}

                {!isLoading && qList}
            </Box>


        </Box >
    )

};

export default QuestionBank;
