import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { config } from "./Const.js";
import { executeFetch } from './Util.js';



export default function SignIn() {


    const [unauthorizedMessage, setUnauthorizedMessage] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const obj = { 'username': data.get('username'), 'password': data.get('password') };

        let url = config.SERVER_URL + "/api/v1/login/authenticate";
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        let responseDataHandler = (result) => {
            localStorage.setItem('token', result.token);
            setUnauthorizedMessage(null);
            window.location.replace("/");
        }

        let errorDataHandler = (result) => {
            setUnauthorizedMessage("Bad Credentials");
        }

        executeFetch(url, "post", headers, JSON.stringify(obj), null, responseDataHandler, errorDataHandler)


    };

    return (

        <Container component="main" maxWidth="xs">

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="User Name"
                        name="username"
                        autoComplete="User Name"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>

                    <Box>
                        {unauthorizedMessage}
                    </Box>

                </Box>
            </Box>

        </Container>

    );
}