import React from 'react';
import { useState, useEffect } from 'react';
import MCQuestion from './MCQuestion.jsx';
import BucketQuestion from './BucketQuestion.jsx';
import { config } from "../Const.js";
import { executeFetch } from '../Util.js';



function QuestionContainer(props) {



    const [variableData, setVariableData] = useState(null);

    const [loading, setLoading] = useState(false);

    const data = variableData ? variableData : props.data;

    const questionData = data.question;
    const criteriaData = data.criteria;
    const selectedMode = props.selectedMode;
    const index = props.index;




    const handleDataChange = props.onDataChange;
    const handleUpDown = props.onUpDown;

    const handleAdd = () => {

        var clonedQuestion = JSON.parse(JSON.stringify(data));
        clonedQuestion.question.templateId = data.question.templateId.split("~~~")[0] + "~~~" + Math.random();
        props.onAdd(clonedQuestion)
    };

    const handleRemove = () => props.onRemove(data);

    const handleDuplicate = () => props.onDuplicate(data);

    const setQuestionParams = (newValue, oldValue, optionType) => {

        let questionParams = criteriaData.questionParams;

        switch (optionType) {

            case "ENTITY":

                questionParams.quizzlerEntity = newValue;
                questionParams.contextQVariable = null;
                questionParams.correctOptionQVariable = null;
                questionParams.distractorQVariableList = null;
                questionParams.questionText = null;
                break;


            case "CONTEXT":


                questionParams.contextQVariable = newValue;
                questionParams.correctOptionQVariable = null;
                questionParams.distractorQVariableList = null;
                questionParams.questionText = null;
                break;





            case "CONTEXT_IMAGE":

                let contextQVariable = questionParams.contextQVariable;
                let imageQVariable = { imageMetadata: newValue };

                if (contextQVariable == null) {
                    questionParams.contextQVariable = { 'imageQVariable': imageQVariable }
                } else {
                    contextQVariable.imageQVariable = imageQVariable;
                }

                break;

            case "SEARCH_IMAGE":

                let searchImageQVariable = { imageMetadata: newValue };
                questionParams.imageQVariable = searchImageQVariable;

                break;

            case "CORRECT_OPTION":
                questionParams.correctOptionQVariable = newValue;

                // changing the correct option should reset the distractors, except for GeneralMCQuestion
                if (!questionData.templateId.startsWith("GeneralMCQuestion")) {
                    questionParams.distractorQVariableList = null;
                }

                break;


            case "DISTRACTOR":

                let d = oldValue;
                d.replaceWith = newValue;
                let l = questionParams.distractorQVariableList;


                if (l == null) {
                    questionParams.distractorQVariableList = [d];
                } else {
                    l.push(d);
                }

                break;




            default:
                break;



        }
    }


    useEffect(() => {


        // Since questions are not saved with the criteria, whenever the question is loaded in the canvas, and the criteria is missing (when loading from the DB), a sendEdit question
        // call is triggered to populate the criteria from the server
        if (questionData.criteria == null && selectedMode) {

            sendEditQuestion();
        }
        // eslint-disable-next-line
    }, []);



    const handleUndo = () => {

        let questionParams = criteriaData.questionParams;

        questionParams.questionText = null;
        questionParams.contextQVariable = null;
        questionParams.correctOptionQVariable = null;
        questionParams.distractorQVariableList = null;

        sendEditQuestion();

    }

    const handleTextChange = (questionText) => {


        let questionParams = criteriaData.questionParams;
        questionParams.questionText = questionText;
        sendEditQuestion();

    }

    const sendEditQuestion = () => {

        setLoading(true);

        let origTemplateId = questionData.templateId;
        let templateId = origTemplateId.split("~~~")[0];
        let url = config.SERVER_URL + "/api/v1/admin/generateQuestions/" + templateId + "/edit"
        let formData = new FormData();

        formData.append('questionParamsJson', JSON.stringify(criteriaData.questionParams));

        const responseHandler = (result) => {

            // Maintain the pseudo-random templateId that was created
            result.question.templateId = origTemplateId;
            setVariableData(result);

            // If change is from the quiz canvas, need to update the question data when a question is changed, to allow question editing in the canvas
            if (selectedMode) {
                handleDataChange(result);
            }
            setLoading(false);

        }

        executeFetch(url, "post", null, formData, null, responseHandler);

    }


    const handleQVariableSelected = (newValue, oldValue, optionType) => {

        setQuestionParams(newValue, oldValue, optionType);

        sendEditQuestion();
    }



    switch (questionData.type) {

        case 'MULTIPLE_CHOICE':
        case 'YES_NO':

            return (<MCQuestion data={data} index={index} key={questionData.templateId} onUpDown={handleUpDown} onUndo={handleUndo} onAdd={handleAdd} onTextChange={handleTextChange} selectedMode={selectedMode} onRemove={handleRemove} onDuplicate={handleDuplicate} onContextVariableSelect={handleQVariableSelected} onQVariableSelect={handleQVariableSelected} loading={loading}></MCQuestion>);

        case 'BUCKET':

            return (<BucketQuestion data={data} key={questionData.templateId} onContextVariableSelect={handleQVariableSelected}></BucketQuestion>);

        default:
            return null;

    }


}

export default QuestionContainer;


