import React from 'react';
import { useState, useEffect } from 'react';
import { config } from "../Const.js";
import { Box, Snackbar, Alert } from '@mui/material';
import { executeFetchAsync } from '../Util.js';
import BasicDetails from './user-details/BasicDetails.jsx';
import PasswordManagement from './user-details/PasswordManagement.jsx';
import AccountStatus from './user-details/AccountStatus.jsx';
import Comms from './user-details/Comms.jsx';
import AdminLog from './user-details/AdminLog.jsx';
import UserLog from './user-details/UserLog.jsx';
import UserStats from './user-details/UserStats.jsx';
import UserNotes from './user-details/UserNotes.jsx';


export default function UserPanel({ userId, onChangeUser, onDeleteUser }) {
    const [user, setUser] = useState();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSaveBasicDetails = async (email) => {


        const url = `${config.SERVER_URL}/api/v1/admin/user/${userId}/email`;
        const body = { "email": email };
        const user = await executeFetchAsync(url, 'PATCH', { 'Content-Type': 'application/json' }, JSON.stringify(body));

        if (onChangeUser) onChangeUser(user);


    };

    const handleResetPassword = async () => {

        const userName = user.userName;
        const url = `${config.SERVER_URL}/api/v1/admin/user/${userName}/reset-password`;

        try {
            await executeFetchAsync(url, 'PATCH');

            setSnackbarMessage('Password reset successful!');
            setSnackbarSeverity('success');
        } catch (e) {

            setSnackbarMessage('Password reset failed. Please try again.');
            setSnackbarSeverity('error');

        } finally {
            setSnackbarOpen(true);
        }


    }

    const handleSuspendAccount = async (user, comment) => {

        const userName = user.userName;
        const url = `${config.SERVER_URL}/api/v1/admin/user/${userName}/suspend`;
        const body = { 'comment': comment };

        try {
            const updatedUser = await executeFetchAsync(url, 'PATCH', { 'Content-Type': 'application/json' }, JSON.stringify(body));
            setUser(updatedUser);
            if (onChangeUser) onChangeUser(updatedUser);

            setSnackbarMessage('Account was suspended successfully!');
            setSnackbarSeverity('success');
        } catch (e) {

            setSnackbarMessage('Unable to suspend account. Please try again.');
            setSnackbarSeverity('error');

        } finally {
            setSnackbarOpen(true);
        }


    }

    const handleActivateAccount = async (user, comment) => {

        const userName = user.userName;
        const url = `${config.SERVER_URL}/api/v1/admin/user/${userName}/activate`;
        const body = { 'comment': comment };

        try {
            const updatedUser = await executeFetchAsync(url, 'PATCH', { 'Content-Type': 'application/json' }, JSON.stringify(body));
            setUser(updatedUser);
            if (onChangeUser) onChangeUser(updatedUser);

            setSnackbarMessage('Account was activated successfully!');
            setSnackbarSeverity('success');
        } catch (e) {

            setSnackbarMessage('Unable to activate account. Please try again.');
            setSnackbarSeverity('error');

        } finally {
            setSnackbarOpen(true);
        }


    }

    const handleDeleteAccount = async (user) => {

        const userName = user.userName;
        const url = `${config.SERVER_URL}/api/v1/admin/user/${userName}`;

        try {
            await executeFetchAsync(url, 'DELETE', { 'Content-Type': 'application/json' }, null);
            if (onDeleteUser) onDeleteUser(user);

            setSnackbarMessage('Account was deleted successfully!');
            setSnackbarSeverity('success');
        } catch (e) {

            setSnackbarMessage('Unable to delete account. Please try again.');
            setSnackbarSeverity('error');

        } finally {
            setSnackbarOpen(true);
        }


    }

    const handleCreateNote = async (note) => {

        const userName = user.userName;
        const url = `${config.SERVER_URL}/api/v1/admin/user/${userName}/notes`;
        const body = { 'note': note };

        try {
            const updatedUser = await executeFetchAsync(url, 'POST', { 'Content-Type': 'application/json' }, JSON.stringify(body));

            setUser(updatedUser);

            setSnackbarMessage('Note Saved Successfully!');
            setSnackbarSeverity('success');
        } catch (e) {

            setSnackbarMessage('Unable to create note. Please try again.');
            setSnackbarSeverity('error');

        } finally {
            setSnackbarOpen(true);
        }

    }


    useEffect(() => {
        const fetchUser = async () => {
            const url = `${config.SERVER_URL}/api/v1/admin/user/${userId}`;
            const user = await executeFetchAsync(url);
            setUser(user);
        };
        fetchUser();
    }, [userId]);

    return (
        <Box sx={{ height: 800, p: 5, overflowY: 'scroll' }}>
            <Box sx={{ display: 'flex', gap: '20px', mb: 2 }}>
                <Box>User ID:</Box>
                <Box>{userId}</Box>
            </Box>
            <BasicDetails user={user} onSave={handleSaveBasicDetails} />

            <PasswordManagement user={user} onResetPassword={handleResetPassword} />

            <AccountStatus user={user} onSuspend={handleSuspendAccount} onActivate={handleActivateAccount} onDelete={handleDeleteAccount} />

            <Comms user={user} />

            <UserStats user={user} />

            <UserLog user={user} />

            <AdminLog user={user} />

            <UserNotes user={user} onCreateNote={handleCreateNote} />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                sx={{
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '400px',
                        backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336',
                        color: '#fff',
                        padding: '8px',
                        fontSize: '1rem',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                    },
                }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Box >
    );
}
