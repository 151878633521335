import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Box } from '@mui/material';
import { isValidEmail } from '../Util';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';



export default function EmailImportDialog({ open, onClose, onImport }) {
    const [campaignName, setCampaignName] = useState('');
    const [campaignDate, setCampaignDate] = useState(null);
    const [csvFile, setCsvFile] = useState(null);
    const [error, setError] = useState('');

    const handleFileChange = (e) => {
        setError('');
        setCsvFile(e.target.files[0]);
    };

    const handleImport = () => {
        if (!campaignName || !campaignDate || !csvFile) {
            setError('All fields are required.');
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            const content = reader.result;
            const emails = content.split('\n').map(row => row.trim());


            const invalidEmails = emails.filter(email => !isValidEmail(email));
            if (invalidEmails.length > 0) {
                setError(`Invalid emails: ${invalidEmails.join(', ')}`);
            } else {
                onImport(campaignName, campaignDate, emails);
                onClose();
            }
        };

        reader.readAsText(csvFile);
    };

    useEffect(() => {
        if (open) {
            setCampaignName('');
            setCampaignDate(null);
            setCsvFile(null);
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Import Email Log</DialogTitle>
            <DialogContent sx={{ width: '600px' }}>
                <Box mb={2} mt={2}>
                    <TextField
                        label="Email Campaign Name"
                        variant="outlined"
                        fullWidth
                        value={campaignName}
                        onChange={(e) => setCampaignName(e.target.value)}
                    />
                </Box>
                <Box mb={2}>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Campaign Date"
                            value={campaignDate}
                            onChange={(date) => setCampaignDate(date)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box mb={2}>
                    <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        style={{ width: '100%' }}
                    />
                </Box>
                {error && <Box color="error.main">{error}</Box>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleImport}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}