import React, { useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatDate } from '../../Util';

export default function AccountStatus({ user, onSuspend, onActivate, onDelete }) {
    const [saving, setSaving] = useState(false);
    const [suspendModalOpen, setSuspendModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [activateModalOpen, setActivateModalOpen] = useState(false);
    const [suspendComment, setSuspendComment] = useState('');
    const [activateComment, setActivateComment] = useState('');
    const createdDate = user?.createdDate;
    const accountStatus = user?.accountStatus;
    const accountLog = user?.accountLog;

    const statusArray = ['USER_REGISTRATION', 'SUSPEND_ACCOUNT', 'ACTIVATE_ACCOUNT', 'DELETE_ACCOUNT'];
    const accountStatusLog = accountLog?.filter(ale => statusArray.indexOf(ale.alm) > -1);

    const handleOpenSuspendModal = () => {
        setSuspendModalOpen(true);
    };

    const handleOpenActivateModal = () => {
        setActivateModalOpen(true);
    };

    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const handleCloseSuspendModal = () => {
        setSuspendModalOpen(false);
        setSuspendComment('');
    };

    const handleCloseActivateModal = () => {
        setActivateModalOpen(false);
        setActivateComment('');
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const handleConfirmSuspend = async () => {
        setSaving(true);
        try {

            await onSuspend(user, suspendComment);
        } finally {
            setSaving(false);
            handleCloseSuspendModal();
        }
    };



    const handleConfirmActivate = async () => {
        setSaving(true);
        try {

            await onActivate(user, activateComment);
        } finally {
            setSaving(false);
            handleCloseActivateModal();
        }
    };

    const handleConfirmDelete = async () => {
        setSaving(true);
        try {

            await onDelete(user);
        } finally {
            setSaving(false);
            handleCloseDeleteModal();
        }
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="account-status-content" id="account-status-header">
                <Typography sx={{ mr: 2 }}>Account Status ({accountStatusLog?.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={4}>
                            <Typography fontSize="0.8em" variant="subtitle1" fontWeight="bold">
                                Created:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography fontSize="0.8em">{formatDate(createdDate)}</Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography fontSize="0.8em" variant="subtitle1" fontWeight="bold">
                                Status:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography fontSize="0.8em">{accountStatus}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography fontSize="0.8em" variant="subtitle1" fontWeight="bold">
                                Status Log:
                            </Typography>
                            {accountStatusLog?.map((as, index) => (
                                <Box key={index}>
                                    <Grid

                                        container
                                        spacing={1}
                                        sx={{ fontSize: '0.8em', mt: '10px' }}
                                        alignItems="center"
                                    >
                                        <Grid item xs={6}>
                                            {formatDate(as.date)}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {as.alm}
                                        </Grid>
                                    </Grid>

                                    <Grid fontSize={'0.7em'} item xs={12}>
                                        {as.message}
                                    </Grid>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>

                    {accountStatus === 'ACTIVE' && <Box display="flex" justifyContent="center" sx={{ mt: 5, mr: 3 }}>
                        <Button
                            sx={{ width: '150px' }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleOpenSuspendModal}
                        >
                            Suspend
                        </Button>
                    </Box>}

                    {accountStatus === 'SUSPENDED' && <Box display="flex" justifyContent="center" sx={{ gap: 2, mt: 5, mr: 3 }}>
                        <Button
                            sx={{ width: '150px' }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleOpenActivateModal}
                        >
                            Activate
                        </Button>


                        <Button
                            sx={{ width: '150px' }}
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={handleOpenDeleteModal}

                        >
                            Delete
                        </Button>


                    </Box>}
                </Box>
            </AccordionDetails>


            <Dialog open={suspendModalOpen} onClose={handleCloseSuspendModal}>
                <DialogTitle>Suspend Account</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Please provide a reason for suspending this account:
                    </Typography>
                    <TextField
                        autoFocus
                        fullWidth
                        multiline
                        minRows={3}
                        placeholder="Enter your reason here..."
                        value={suspendComment}
                        onChange={(e) => setSuspendComment(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuspendModal} color="primary" disabled={saving}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmSuspend}
                        color="primary"
                        variant="contained"
                        disabled={saving || !suspendComment.trim()}
                        startIcon={saving && <CircularProgress size={20} />}
                    >
                        {saving ? 'Suspending...' : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={activateModalOpen} onClose={handleCloseActivateModal}>
                <DialogTitle>Activate Account</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Please provide a reason for activating this account:
                    </Typography>
                    <TextField
                        autoFocus
                        fullWidth
                        multiline
                        minRows={3}
                        placeholder="Enter your reason here..."
                        value={activateComment}
                        onChange={(e) => setActivateComment(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseActivateModal} color="primary" disabled={saving}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmActivate}
                        color="primary"
                        variant="contained"
                        disabled={saving || !activateComment.trim()}
                        startIcon={saving && <CircularProgress size={20} />}
                    >
                        {saving ? 'Activating...' : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteModalOpen} onClose={handleCloseDeleteModal}>
                <DialogTitle>Delete Account</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Are you sure you want to delete the account? This will delete the user permanently.
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteModal} color="primary" disabled={saving}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        color="error"
                        variant="contained"
                        disabled={saving}
                        startIcon={saving && <CircularProgress size={20} />}
                    >
                        {saving ? 'Deleting...' : 'Delete Account'}
                    </Button>
                </DialogActions>
            </Dialog>

        </Accordion>
    );
}
