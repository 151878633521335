import React, { useState } from 'react';

import { Box, Tabs, Tab } from '@mui/material';
import QuizAdmin from './quizzes/QuizAdmin.jsx';
import CategoryPageAdmin from './pages/CategoryPageAdmin.jsx';
import quizmydayLogo from "./quizmyday-logo.png";
import SocialHub from './social-hub/SocialHub.jsx';
import UserAdmin from './users/UserAdmin.jsx';





function MainEditor() {

    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };




    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center' }}>

                <Box sx={{ height: '50px', mr: '30px' }}><img alt="quizmyday" height={50} src={quizmydayLogo}></img></Box>


                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Quizzes" index={0} ></Tab>

                    <Tab label="Category Pages" index={1} />
                    <Tab label="Social Hub" index={2} />
                    <Tab label="User Management" index={3} />
                </Tabs>


            </Box>
            {tabIndex === 0 && <QuizAdmin />}

            {tabIndex === 1 && <CategoryPageAdmin />}

            {tabIndex === 2 && <SocialHub />}

            {tabIndex === 3 && <UserAdmin />}
        </Box >

    )

};

export default MainEditor;
