import React, { useState, useEffect } from 'react';
import { config } from "./Const.js";
import 'reactjs-popup/dist/index.css';
import { TextField, Autocomplete, Chip, Box } from '@mui/material';


import { executeFetch } from './Util.js';


function TagSelector(props) {


    const value = props.selected ?? [];
    const notifyTagValuesChange = props.onTagValuesChange || (() => { });
    const notifyMainTagChange = props.onMainTagChange || (() => { });
    const mainTag = props.mainTag;
    const [availableTagList, setAvailableTagList] = useState([]);

    const generateAllTagUrl = () => {
        return config.SERVER_URL + "/api/v1/admin/entityData/allTags";
    }

    const fetchAvailableTagList = () => {
        let url = generateAllTagUrl();
        let responseDataHandler = (result) => {

            setAvailableTagList(result);

        }

        executeFetch(url, "get", null, null, null, responseDataHandler)
    }




    useEffect(() => {
        fetchAvailableTagList();

        // eslint-disable-next-line
    }, []);





    const getOptionSelected = (option, value) => option.label === value.label;



    const handleChipClick = (option) => {


        /*
        if (mainTag?.label === option.label) {

            console.log('equal tags');
            notifyMainTagChange(null);

        } else {
            */

        notifyMainTagChange(option);
        //}

    }



    return (

        <Box>
            <Autocomplete
                multiple
                id="tags-filled"
                options={availableTagList}
                getOptionLabel={(option) => option.label}
                getOptionSelected={getOptionSelected}

                value={value}
                onChange={(event, newValue) => {

                    const modifiedValues = newValue.map((option) =>

                        // If the user enters text in freesolo mode, change the value to a quizzlerobject form
                        typeof option === 'string' ? { label: option } : option
                    );

                    notifyTagValuesChange(modifiedValues);
                }}
                freeSolo
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip variant="outlined" sx={{ backgroundColor: (mainTag?.label === option.label ? '#1976d2' : 'white'), color: (mainTag?.label === option.label ? 'white' : 'black') }} onClick={() => handleChipClick(option)} label={option.label} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"

                        placeholder="Tags"
                    />
                )}
            />



        </Box>
    )
};

export default TagSelector;