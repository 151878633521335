import React, { useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    CircularProgress,

} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatDate } from '../../Util';

export default function PasswordManagement({ user, onResetPassword }) {
    const [loading, setLoading] = useState(false);
    const identityProvider = user?.identityProvider;
    const accountLog = user?.accountLog;
    const lastPasswordUpdated = formatDate(accountLog?.filter(ale => ale.alm === 'CONFIRM_NEW_PASSWORD' || ale.alm === 'USER_REGISTRATION').at(-1)?.date);

    const handleResetPasswordClick = async () => {
        if (onResetPassword) {
            setLoading(true);
            try {
                await onResetPassword(user);
            } catch (error) {
                console.error("Error resetting password:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="password-management-content" id="password-management-header">
                <Typography sx={{ mr: 2 }}>Password Management</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Sign In Method:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>{identityProvider}</Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Last Updated:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>{identityProvider === 'EMAIL' ? lastPasswordUpdated : 'N/A'}</Typography>
                        </Grid>
                    </Grid>

                    <Box display="flex" justifyContent="center" sx={{ mt: 3, mr: 3 }}>
                        <Button
                            disabled={identityProvider !== 'EMAIL' || loading}
                            size="small"

                            variant="contained"
                            color="primary"
                            onClick={handleResetPasswordClick}
                            sx={{ position: 'relative', width: '160px', height: '36px' }}
                        >
                            {loading ? (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            ) : (
                                'Reset Password'
                            )}
                        </Button>
                    </Box>
                </Box>
            </AccordionDetails>


        </Accordion>
    );
}
