import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,

} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatDate } from '../../Util';

export default function UserLog({ user }) {


    const accountLog = user?.accountLog;

    const userLog = accountLog?.filter(ale => ale.alit === "USER");

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="email-log-content" id="email-log-header">
                <Typography sx={{ mr: 2 }}>User Log ({userLog?.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>

                    {userLog?.map((as, index) => (

                        <Box key={index} sx={{ mb: '5px' }}>

                            <Grid

                                container
                                spacing={1}
                                sx={{ fontSize: '0.8em', mt: '10px' }}
                                alignItems="center"
                            >
                                <Grid item xs={4}>
                                    {formatDate(as.date)}
                                </Grid>
                                <Grid item xs={4}>
                                    {as.alm}
                                </Grid>

                            </Grid>

                            <Grid item xs={12} sx={{ fontSize: '0.8rem' }}>
                                {as.message}
                            </Grid>
                        </Box>


                    ))}



                </Box>
            </AccordionDetails>

        </Accordion>
    );
}
