import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,

} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function UserStats({ user }) {


    const userStats = user?.userStatsDTO;
    const quizCount = userStats?.numOfCompletedQuizzesScore;

    console.log(user);



    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="user-stats-content" id="user-stats-header">
                <Typography sx={{ mr: 2 }}>User Stats ({quizCount} quizzes)</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ mb: '5px' }}>

                    <Grid
                        container
                        spacing={1}
                        sx={{ fontSize: '0.9em' }}
                        alignItems="center"
                    >
                        {[
                            {
                                label: 'Quizzes completed:',
                                content: `Count: ${userStats?.numOfCompletedQuizzesScore}, Level: ${userStats?.numOfCompletedQuizzesLevel}`
                            },
                            {
                                label: 'Total Points:',
                                content: `Count: ${userStats?.totalPointsScore}, Level: ${userStats?.totalPointsLevel}`
                            },
                            {
                                label: 'Most Played:',
                                content: userStats?.mostCategoriesList.map((c, index) => (
                                    <Box key={index} sx={{ fontSize: '0.8em' }}>
                                        {c.label} ({c.value})
                                    </Box>
                                )),
                                alignTop: true
                            },
                            {
                                label: 'Correct category answers:',
                                content: userStats?.bestCategoriesList.map((c, index) => (
                                    <Box key={index} sx={{ fontSize: '0.8em' }}>
                                        {c.label} ({(c.value * 100).toFixed(0)}%)
                                    </Box>
                                )),
                                alignTop: true
                            },
                            {
                                label: 'Correct artist answers:',
                                content: userStats?.bestArtistList.map((a, index) => (
                                    <Box key={index} sx={{ fontSize: '0.8em' }}>
                                        {a.label} ({(a.value * 100).toFixed(0)}%)
                                    </Box>
                                )),
                                alignTop: true
                            }
                        ].map((item, index) => (
                            <React.Fragment key={index}>
                                <Grid
                                    item
                                    xs={6}
                                    sx={item.alignTop ? { alignSelf: 'flex-start' } : null}
                                >
                                    {item.label}
                                </Grid>
                                <Grid item xs={6}>
                                    {item.content}
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>



                </Box>

            </AccordionDetails>

        </Accordion>
    );
}
