import React, { useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    CircularProgress,
    DialogActions,
    DialogTitle,
    Dialog,
    TextField,
    DialogContent,

} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatDate } from '../../Util';

export default function UserNotes({ user, onCreateNote }) {


    const accountLog = user?.accountLog;

    const [saving, setSaving] = useState(false);
    const [note, setNote] = useState('');
    const [noteModalOpen, setNoteModalOpen] = useState(false);

    const handleCreateNoteClick = () => {
        setNoteModalOpen(true);
    }

    const handleCreateNoteSave = () => {
        setSaving(true);
        onCreateNote(note);
        setSaving(false);
        setNote('');
        handleCloseNoteModal();
    }


    const handleCloseNoteModal = () => {
        setNoteModalOpen(false);
    }

    const notesLog = accountLog?.filter(ale => ale.alm === "CREATE_NOTE");

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="email-log-content" id="email-log-header">
                <Typography sx={{ mr: 2 }}>User Notes ({notesLog?.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>

                    {notesLog?.map((as, index) => (

                        <Box key={index} sx={{ mb: '5px' }}>

                            <Grid

                                container
                                spacing={1}
                                sx={{ fontSize: '0.8em', mt: '10px' }}
                                alignItems="top"
                            >
                                <Grid item xs={4}>
                                    {formatDate(as.date)}
                                </Grid>

                                <Grid item xs={8} sx={{ fontSize: '0.8rem' }}>
                                    {as.message}
                                </Grid>
                            </Grid>
                        </Box>


                    ))}



                </Box>

                <Box display="flex" justifyContent="center" sx={{ mt: 3, mr: 3 }}>

                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleCreateNoteClick}
                        sx={{ position: 'relative', width: '160px', height: '36px' }}
                    >

                        Create Note

                    </Button>

                </Box>
            </AccordionDetails>

            <Dialog open={noteModalOpen} onClose={handleCloseNoteModal}>
                <DialogTitle>Create Note</DialogTitle>
                <DialogContent sx={{ width: '600px' }}>

                    <TextField
                        autoFocus
                        fullWidth
                        multiline
                        minRows={3}
                        placeholder="Enter your note here..."
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNoteModal} color="primary" disabled={saving}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleCreateNoteSave}
                        color="primary"
                        variant="contained"
                        disabled={saving || !note.trim()}
                        startIcon={saving && <CircularProgress size={20} />}
                    >
                        {saving ? 'Saving...' : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>

        </Accordion>
    );
}
