import React, { useEffect, useState } from 'react';

import { Box, Typography, Paper, } from '@mui/material';
import CategoryPageQuizList from './CategoryPageQuizList.jsx';
import CategoryPageSelectedList from './CategoryPageSelectedList.jsx';
import { executeFetch } from '../Util.js';
import { config } from '../Const.js';




function CategoryPageEditor(props) {


    const page = props.page;
    const tag = page?.tagList[0].label;
    const FEATURED_TAG = 'featured';
    const POPULAR_TAG = 'popular';

    const [availableQuizList, setAvailableQuizList] = useState([]);
    const [selectedQuizList, setSelectedQuizList] = useState([]);
    const notifyPageContentChanged = props.onChange;



    const handleAdded = (contentEntity) => {

        let updatedAvailableQuizList = availableQuizList.filter(item => item !== contentEntity)
        let updatedSelectedQuizList = [...selectedQuizList, contentEntity];

        setAvailableQuizList(updatedAvailableQuizList);
        setSelectedQuizList(updatedSelectedQuizList);
        notifyPageContentChanged(updatedSelectedQuizList);

    }


    const handleRemoved = (contentEntity) => {

        let updatedAvailableQuizList = [...availableQuizList, contentEntity];
        let updatedSelectedQuizList = selectedQuizList.filter(item => item !== contentEntity)

        setAvailableQuizList(updatedAvailableQuizList);
        setSelectedQuizList(updatedSelectedQuizList);

        notifyPageContentChanged(updatedSelectedQuizList);

    }

    const handleUpClicked = (contentItem) => {

        let updatedSelectedQuizList = [...selectedQuizList];
        const currentIndex = updatedSelectedQuizList.indexOf(contentItem);

        if (currentIndex > 0) {
            const prevIndex = currentIndex - 1;
            updatedSelectedQuizList.splice(currentIndex, 1);
            updatedSelectedQuizList.splice(prevIndex, 0, contentItem);
        }

        setSelectedQuizList(updatedSelectedQuizList);
        notifyPageContentChanged(updatedSelectedQuizList);


    }


    const handleDownClicked = (contentItem) => {

        let updatedSelectedQuizList = [...selectedQuizList];
        const currentIndex = updatedSelectedQuizList.indexOf(contentItem);

        if (currentIndex !== -1 && currentIndex < updatedSelectedQuizList.length - 1) {
            const nextIndex = currentIndex + 1;
            updatedSelectedQuizList.splice(currentIndex, 1);
            updatedSelectedQuizList.splice(nextIndex, 0, contentItem);
        }

        setSelectedQuizList(updatedSelectedQuizList);
        notifyPageContentChanged(updatedSelectedQuizList);


    }

    const fetchQuizList = () => {
        var timeout;

        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {

            let url;

            if (tag === POPULAR_TAG) {
                url = config.SERVER_URL + "/api/v1/admin/quiz/popular";
            } else {
                url = config.SERVER_URL + "/api/v1/admin/quiz/filter-by-tags?tagList=";

                if (tag !== FEATURED_TAG) {
                    url += tag;
                }
            }



            let responseHandler = (quizList) => {

                let savedQuizList = page?.ceList || [];
                let filteredQuizList = quizList.filter(q => q.published).filter(q => !(savedQuizList.some(ce => q.id === ce.id)));

                setAvailableQuizList(filteredQuizList);

            }
            executeFetch(url, "get", null, null, null, responseHandler)


        }, 300);
    }

    useEffect(() => {

        setSelectedQuizList(page?.ceList || [])

    }, [page]);


    useEffect(() => {

        fetchQuizList();
        // eslint-disable-next-line
    }, [page]);




    return (

        <Box sx={{ height: '100%' }}>
            <Typography variant="h6">Category Page Editor</Typography>
            <Box sx={{ height: 3 }}></Box>


            <Paper elevation={10} sx={{ height: '92%', pl: '30px', pb: '20px', pr: '20px', pt: '10px' }}>

                <Box sx={{ display: 'flex', mt: '20px', gap: '20px', height: '100%' }}>

                    <Box sx={{ width: '50%' }}>

                        <CategoryPageQuizList onAdd={handleAdded} quizList={availableQuizList} ></CategoryPageQuizList>

                    </Box>
                    <Box sx={{ width: '50%' }}>

                        <CategoryPageSelectedList onRemove={handleRemoved} onUp={handleUpClicked} onDown={handleDownClicked} quizList={selectedQuizList}></CategoryPageSelectedList>
                    </Box>
                </Box>

            </Paper >

        </Box >
    );

};

export default CategoryPageEditor;
