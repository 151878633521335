import React from 'react';
import { useState } from 'react';
import AudioPlayer from "./AudioPlayer.jsx";
import QuestionToolbar from "./QuestionToolbar.jsx";
import QuestionOption from "./QuestionOption.jsx";
import QuestionFreeTextOption from "./QuestionFreeTextOption.jsx";
import InputContainer from '../InputContainer.jsx';
import ImageSelector from './ImageSelector.jsx';
import { IMAGE_SELECTOR_IN_QUIZ_QUESTION, LOCK_ASPECT_QUESTION, config } from "../Const.js";

import { Box, Typography, Paper } from '@mui/material';

function MCQuestion(props) {

    const [answerHidden, setAnswerHidden] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const loading = props.loading;

    const questionData = props.data.question;
    const criteriaData = props.data.criteria;
    const index = props.index;

    const handleQVariableSelected = props.onQVariableSelect;
    const handleDuplicate = props.onDuplicate;
    const handleUpDown = props.onUpDown;

    const handleAdd = props.onAdd;
    const handleRemove = props.onRemove;
    const selectedMode = props.selectedMode;
    const onTextChange = props.onTextChange;
    const questionImage = questionData.imd;

    const onAudioTextChange = (value) => {

        let audioText = questionText.substring(0, questionText.lastIndexOf("^^") + 2);
        value = audioText + value;

        onTextChange(value);
    }


    const generateGalleryDataUrl = () => {

        let wikidataId;
        let galleryDataUrl;
        if (isContextImageQVariableAvailable()) {
            wikidataId = criteriaData.questionParams.contextQVariable.quizzlerObject.idMapping.WIKIDATA;

        } else {
            let qe = criteriaData.questionParams.quizzlerEntity;

            if (qe) {
                wikidataId = criteriaData.questionParams.quizzlerEntity.idMapping.WIKIDATA;
            }

        }

        // gallery data should be empty when creating a question from scratch
        if (wikidataId) {

            galleryDataUrl = config.SERVER_URL + "/api/v1/admin/entityData/photos?wikidataIdList=" + wikidataId;
        }


        return galleryDataUrl;

    }

    const isContextImageQVariableAvailable = () => {

        let contextQVariable = criteriaData.questionParams.contextQVariable;
        return contextQVariable && contextQVariable.imageQVariable;
    }

    const isAudioQuestion = (questionText) => {
        return questionText && questionText.startsWith('^^');
    }

    const handleEditClicked = () => {
        setEditMode(!editMode);
    };

    const handleImageSelected = (imd) => {


        let imageOrigin;

        // image coming from context gallery
        if (contextImageMode) {
            imageOrigin = "CONTEXT_IMAGE";

            // image coming from image search
        } else {
            imageOrigin = "SEARCH_IMAGE";
        }


        handleQVariableSelected(imd, null, imageOrigin);
    }


    const handleAltChange = (value) => {

        let questionImageClone = JSON.parse(JSON.stringify(questionImage));
        questionImageClone.alt = value;
        handleImageSelected(questionImageClone);


    }



    const handleUndo = props.onUndo;

    const contextImageMode = isContextImageQVariableAvailable();

    var correctUUID = questionData.correctOption.uuid;

    let freeTextMode = questionData.templateId.startsWith('GeneralMCQuestion');

    var answerList = questionData.displayedOptionList.map(function (a) {



        var answerUUID = a.uuid;

        return (!freeTextMode ?

            <QuestionOption key={a.uuid} type={answerUUID === correctUUID ? "CORRECT_OPTION" : "DISTRACTOR"} option={a} optionList={questionData.displayedOptionList} criteriaData={criteriaData} answerHidden={answerHidden} editMode={editMode} onQVariableSelect={handleQVariableSelected}></QuestionOption>
            :

            <QuestionFreeTextOption key={a.uuid} type={answerUUID === correctUUID ? "CORRECT_OPTION" : "DISTRACTOR"} option={a} optionList={questionData.displayedOptionList} answerHidden={answerHidden} editMode={editMode} onQVariableSelect={handleQVariableSelected} />



        )

    });


    var questionText = questionData.textMessage;

    var questionHTML;
    if (isAudioQuestion(questionText)) {

        var audioUrl = questionText.substring(2, questionText.lastIndexOf('^^'));
        var audioText = questionText.substring(questionText.lastIndexOf('^^') + 2);
        questionHTML =
            <Box sx={{ marginBottom: '20px', marginTop: '10px' }}>
                {editMode && <InputContainer key={audioText} label='Question Text' value={audioText} onBlur={onAudioTextChange} />}
                {!editMode && <Typography variant='h6'>{audioText}</Typography>}
                <AudioPlayer audioUrl={audioUrl}></AudioPlayer>
            </Box>;

    } else {


        questionHTML =
            <Box sx={{ marginBottom: '20px', marginTop: '10px' }}>

                <Box sx={{ marginTop: '20px' }}>

                    <ImageSelector layout={IMAGE_SELECTOR_IN_QUIZ_QUESTION} imd={questionImage} lockAspect={LOCK_ASPECT_QUESTION} galleryDataUrl={generateGalleryDataUrl()} onAltChange={handleAltChange} onSelect={handleImageSelected} ></ImageSelector>

                </Box>
                <Box sx={{ marginTop: '20px' }}>
                    {editMode && <InputContainer key={questionText} label='Question Text' value={questionText} onBlur={onTextChange} />}
                    {!editMode && <Typography variant='h6'>{questionText}</Typography>}
                </Box>
            </Box >;
    }

    var answerTextMessage = questionData.answerTextMessage
    var answerTextHtml = <Box className='answerTextMessage'>{answerTextMessage}</Box>;


    const handleShowHideAnswer = () => {

        setAnswerHidden(!answerHidden);

    }



    return (
        <Box>
            <Paper elevation={3} sx={{ pl: '30px', pr: '30px', pt: '10px', pb: '30px', backgroundColor: 'inherit' }} className={`${loading ? 'shimmer' : ''}`} key={questionData.templateId}>
                <Box className="questionHeaderContainer">

                    <QuestionToolbar freeTextMode={freeTextMode} index={index} data={criteriaData} onUndo={handleUndo} onDuplicate={handleDuplicate} onAdd={handleAdd} onRemove={handleRemove} selectedMode={selectedMode} editMode={editMode} onEdit={handleEditClicked} onQVariableSelect={handleQVariableSelected} onUpDown={handleUpDown} onShowHideAnswer={handleShowHideAnswer} answerHidden={answerHidden} />

                </Box>
                <Box>{questionHTML}</Box>

                {answerList}

                <Typography variant="caption" >{answerTextMessage && !answerHidden ? answerTextHtml : null}</Typography>


            </Paper>
        </Box >

    )

};

export default MCQuestion;
