import React, { useState, useEffect } from 'react';
import { config } from "../Const.js";
import 'reactjs-popup/dist/index.css';
import { TextField, Button, Box } from '@mui/material';
import TagSelector from '../TagSelector.jsx';
import { executeFetch } from '../Util.js';


function QuizMetadata(props) {

    const valueOrEmpty = (str) => {
        return str ?? '';
    }



    const data = props.data ?? {};

    let questionData = props.questionData;
    let quizName = valueOrEmpty(data.quizName);
    const mainTag = valueOrEmpty(data.mainTag);
    let description = valueOrEmpty(data.description);
    const selectedTagList = data.tagList;

    let quizId = data.id;

    const metadataChanged = props.onChange;

    const [defaultTagList, setDefaultTagList] = useState([]);




    const setProperty = (name, value) => {

        let quizMetadataClone = JSON.parse(JSON.stringify(data));

        quizMetadataClone[name] = value;
        metadataChanged(quizMetadataClone);
    }


    const setPropertyList = (propertyNameValueArr) => {

        let quizMetadataClone = JSON.parse(JSON.stringify(data));

        propertyNameValueArr.forEach(p => quizMetadataClone[p[0]] = p[1]);

        metadataChanged(quizMetadataClone);
    }


    const handleNameChange = (e) => {

        setProperty('quizName', e.target.value)

    }


    const handleDescriptionChange = (e) => {

        setProperty('description', e.target.value)

    }





    const getSelectedEntityIds = () => {
        let selectedEntityIds = [...new Set(questionData.map(q => {

            let qe = q.criteria.questionParams.quizzlerEntity;

            if (qe) {
                return qe.idMapping.WIKIDATA;
            } else {
                return null;
            }

        }).filter(q => q != null))];

        return selectedEntityIds;
    }


    const generateEntityTagUrl = () => {

        let url = null;
        let selectedEntityIds = getSelectedEntityIds();

        if (selectedEntityIds.length > 0) {
            let selectEntityIdsSearchStr = selectedEntityIds.join(",");
            url = config.SERVER_URL + "/api/v1/admin/entityData/tags?wikidataIdList=" + selectEntityIdsSearchStr;
        }

        return url;

    }

    const generateName = (qo) => {


        return new Promise((resolve, reject) => {
            let url = config.SERVER_URL + "/api/v1/admin/quiz/generateIndexByTag";

            let formData = new FormData();
            formData.append('tag', JSON.stringify(qo));

            if (quizId) {
                formData.append('quizId', quizId);
            }


            let responseDataHandler = (serialNumber) => {

                const qoLabel2QuizNameMap = {
                    '80s': '80s Music',
                    '90s': '90s Music',
                    'pop': 'Pop Music',
                    '60s': '60s Music',
                    '70s': '70s Music',
                    '2000s': '2000s Music',
                    'folk': 'Folk Music',
                    'country': 'Country Music',
                    'latin': 'Latin Music',
                    'alternative': 'Alternative Music'
                }

                let qoLabel = qoLabel2QuizNameMap[qo.label.toLowerCase()] ?? qo.label;
                let capitalizedLabel = qoLabel.replace(/\p{L}\S*/gu, word => word.charAt(0).toUpperCase() + word.slice(1));
                let quizName = capitalizedLabel + " Quiz #" + serialNumber;

                resolve({ quizName, serialNumber });
            }

            executeFetch(url, "post", null, formData, null, responseDataHandler);
        });

    }



    const fetchDefaultTagList = () => {
        let url = generateEntityTagUrl();

        if (url != null) {


            let responseDataHandler = (result) => {

                setDefaultTagList(result);
            }

            executeFetch(url, "get", null, null, null, responseDataHandler);

        }
    }


    const calcMainEntity = () => {

        let isMultipleMaxElements = false;

        let frequencyMap = {};

        // Count the frequency of each element
        questionData.forEach(q => {
            let qe = q.criteria.questionParams.quizzlerEntity;
            if (qe) {
                frequencyMap[qe.idMapping.WIKIDATA] = (frequencyMap[qe.idMapping.WIKIDATA] || 0) + 1;
            }
        });

        let maxFrequency = 0;
        let mostFrequentElement;

        // Find the element with the highest frequency
        for (let key in frequencyMap) {
            if (frequencyMap[key] > maxFrequency) {
                maxFrequency = frequencyMap[key];
                mostFrequentElement = key;
                isMultipleMaxElements = false;
            } else {
                if (frequencyMap[key] === maxFrequency) {
                    isMultipleMaxElements = true;
                }
            }
        }

        return isMultipleMaxElements ? null : mostFrequentElement;


    }

    // defaultTagList is the common denominator of all tags of questions (if more than one entity, it will be probably the genre, eg classic rock) 
    // The main tag is preferably the QE (if it exists in the default tags), or the most common default tag. 
    const calcMainTag = (defaultTagList) => {

        let mainEntityTag = calcMainEntity();
        let mostFrequentEntityTag = null;

        if (mainEntityTag) {
            mostFrequentEntityTag = defaultTagList.filter(t => t.idMapping?.WIKIDATA === mainEntityTag)[0];
        }

        return mostFrequentEntityTag;

    }


    const handleTagValuesChanged = (tagList) => {

        setProperty('tagList', tagList);

    }

    const handleMainTagChanged = (mainTag) => {

        generateName(mainTag)
            .then(({ quizName, serialNumber }) => {


                setPropertyList([['mainTag', mainTag], ['quizName', quizName], ['serialNumber', serialNumber]]);
            })
            .catch(error => {
                console.error("Error:", error);
            });

    }

    const handleTagGenerateClicked = () => {

        let mainTag = calcMainTag(defaultTagList);

        if (mainTag) {

            generateName(mainTag)
                .then(({ quizName, serialNumber }) => {

                    setPropertyList([['mainTag', mainTag], ['quizName', quizName], ['tagList', defaultTagList], ['serialNumber', serialNumber]]);

                })
                .catch(error => {
                    console.error("Error:", error);
                });

        } else {
            setProperty('tagList', defaultTagList);
        }

    }


    useEffect(() => {

        if (questionData && questionData.length > 0) {

            fetchDefaultTagList();
        }

        // eslint-disable-next-line
    }, [questionData]);




    return (

        <Box>

            <Box sx={{ display: 'flex', height: '40px', gap: '5px', marginBottom: '20px', alignItems: 'flex-end' }}>
                <TextField sx={{ width: '100%', height: '100%' }} size="small" label="Quiz Title" value={quizName} variant="outlined" onChange={handleNameChange}></TextField>
            </Box>

            <Box sx={{ display: 'flex', height: '40px', gap: '5px', marginBottom: '20px', alignItems: 'flex-end' }}>
                <TextField InputLabelProps={{
                    shrink: !!description || undefined,
                }} sx={{ width: '100%', height: '100%' }} size="small" label="Quiz Subject" value={description} variant="outlined" onChange={handleDescriptionChange}></TextField>
            </Box>




            <Box sx={{ mb: '20px' }}>
                <TagSelector mainTag={mainTag} selected={selectedTagList} onMainTagChange={handleMainTagChanged} onTagValuesChange={handleTagValuesChanged}></TagSelector>
                <Button size="small" sx={{ mt: '3px' }} onClick={handleTagGenerateClicked}>Generate</Button>
            </Box>




        </Box >
    )
};

export default QuizMetadata;