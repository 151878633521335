import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import InputContainer from '../InputContainer';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';


function QuestionFreeTextOption(props) {



    const option = props.option;
    const optionType = props.type;
    const optionList = props.optionList;
    const editMode = props.editMode;

    const answerHidden = props.answerHidden;
    const handleQVariableSelected = props.onQVariableSelect;


    const clickRemoveOption = (e, origQVariable, optionType) => {
        handleQVariableSelected(null, origQVariable, optionType);
    }




    const handleOptionTextChanged = (value, qVariable) => {

        let oldValue = qVariable;
        let newValue = JSON.parse(JSON.stringify(qVariable));
        newValue.label = value;
        newValue.quizzlerObject.label = value;
        handleQVariableSelected(newValue, oldValue, optionType);

    }


    const generateOptionTextChange = (qVariable) => {

        return <InputContainer tiny value={qVariable.label} onBlur={(value) => handleOptionTextChanged(value, qVariable)} />;

    }

    const generateOption = (origQVariable) => {

        return (

            <Box>

                <Box sx={{ display: 'flex', height: editMode ? 40 : 30, alignItems: 'center' }}>

                    {optionType === 'DISTRACTOR' && editMode && optionList.length > 2 &&

                        <IconButton aria-label="remove" size="small" onClick={(e) => clickRemoveOption(e, origQVariable, optionType)}><DeleteOutlinedIcon fontSize="small" /></IconButton>

                    }

                    {optionType === 'DISTRACTOR' && editMode && optionList.length <= 2 &&

                        <Box style={{ width: 30 }}></Box>

                    }

                    {optionType === 'CORRECT_OPTION' && editMode &&

                        <Box style={{ width: 30 }}></Box>

                    }

                    {editMode && generateOptionTextChange(origQVariable)}

                    {!editMode && <Box sx={{ alignSelf: 'center' }}><Typography sx={!answerHidden && optionType === 'CORRECT_OPTION' ? { fontWeight: 'bold', color: 'green' } : {}}>{option.label}</Typography></Box>}


                </Box>

            </Box >

        )

    }


    return generateOption(option.qv);






}

export default QuestionFreeTextOption;